import React from 'react';
import MyPage from "../Components/MyPage";
import gameIcon from "../Assets/images/1a2b.png";
import rts from "../Assets/images/rts_square.png";
import googlePlayTr from "../Assets/images/google-play-badge-en.png";
import {yellow} from "../Values/Colors";

const PortfolioScreen = () => {
  return (
    <MyPage style={{ backgroundColor: yellow, justifyContent: "center", alignItems: "center" }}>
      <div className="d-flex container justify-content-lg-center align-items-lg-center">
        <div className="row">
          <div className={"col-12"}>
            <div className={"row"}>
              {portfolios.map(item => {
                return <div key={item.title} className="col-12 col-lg-6 mb-4 mt-4">
                  <div className="col-12 text-center">
                    <img className="image-custom"
                      src={item.image}
                      style={{ borderRadius: 30 }} />
                  </div>
                  <div className="col-12 align-items-center text-center pt-2">
                    <h2 className="text-center mb-2 mt-2">{item.title}</h2>
                    {item.description.map(p => <p className="text-center mb-1">{p}</p>)}
                    {item.linkComponent}
                  </div>
                </div>
              })}
            </div>
          </div>
        </div>
      </div>
    </MyPage>
  );
}

export default PortfolioScreen;

const portfolios = [{
  title: "RTS Online Payment System",
  image: rts,
  description: [
    "Now, Royal Turkish Schools receives online payments from all over the world. Securely.",
    "Tools: React, Express, MongoDB, Heroku"
  ],
  linkComponent: <a href="https://school.royalturkish.com" target="_blank">
    Take a look
  </a>
}, {
  title: "1A2B Multiplayer",
  image: gameIcon,
  description: [
    "1A2B is a simple number guessing game which is known and played in many countries.",
    "Tools: React Native, NodeJS, Socket.IO"
  ],
  linkComponent: <a href="https://play.google.com/store/apps/details?id=com.yalcinozer.multiplayer1a2b" target="_blank">
    <img src={googlePlayTr} style={{ maxWidth: 175 }} />
  </a>
}]
