import React, {useEffect, useState} from "react";

/**
 * This component wraps children components in a row class
 */
const MyPage = (props) => {

  const [state, setState] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setState(" active-page")
    }, 50)
  }, [])

  const {style} = props;
  const classWillBeAdded = "d-flex justify-content-center align-items-center vw-100 page" + state

  return (
      <div
          className={classWillBeAdded}
          style={{paddingTop: 35,height:window.innerHeight, ...style}}
      >
        <div className={"page-inner"}>
          {props.children}
        </div>
      </div>
  )
}

export default MyPage
