import React from "react";
import {Switch, Route, NavLink, BrowserRouter} from "react-router-dom";
import Home from "../Pages/HomePage";
import Game from "../Pages/GamePage";
import Portfolio from "../Pages/PortfolioPage";
import AboutMe from "../Pages/AboutMePage";
import {NotFoundPage} from "../Components/NotFound";
import {Policy} from "../Pages/Policy/Multiplayer1a2b";

const Router = () => {
  return (
      <BrowserRouter>
        <nav>
          <ul>
            <li><NavLink to="/">Home</NavLink></li>
            <li><NavLink to="/game">1A2B</NavLink></li>
            <li><NavLink to="/portfolio">Portfolio</NavLink></li>
            <li><NavLink to="/about-me">About</NavLink></li>
          </ul>
        </nav>

        <Switch>
          <Route path="/game">
            <Game/>
          </Route>
          <Route path="/portfolio">
            <Portfolio/>
          </Route>
          <Route path="/about-me">
            <AboutMe/>
          </Route>
          <Route path="/privacy">
              <Policy/>
          </Route>
          <Route exact path="/">
            <Home/>
          </Route>
          <Route path="*">
            <NotFoundPage/>
          </Route>
        </Switch>

      </BrowserRouter>
  )
}

export {Router};
