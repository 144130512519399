import React from "react";
import styled from "styled-components"

const Wrapper = styled.div`
    min-height: 100vh;
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const NotFoundPage = ()=>{
    return <Wrapper>
        <h1>Not Found</h1>
    </Wrapper>
}