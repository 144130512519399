import React from 'react';
import MyPage from "../Components/MyPage";
import photo from "./../Assets/images/yalcin4.jpeg"
import linkedin from "./../Assets/images/linkedIn.png"
import github from "./../Assets/images/github.png"
import {yellow} from "../Values/Colors";
import {CenterWrapper} from "../Components/CenterWrapper";

const AboutMeScreen = () => {
  return (
      <MyPage style={{backgroundColor: yellow}}>
        <CenterWrapper>
          <>
            <div className="col-12 col-lg-6 text-center">
              <img
                className="image-custom"
                src={photo}
                style={{borderRadius: 30}}
                alt={"Yalcin OZER, Yalçın ÖZER"}
              />
            </div>
            <div className="col-12 col-lg-6 text-center text-lg-left pt-2">
              <h2>About</h2>
              <p>...</p>
              <p>E-mail: <a href="mailto:yalcin.ozer@yahoo.com">yalcin.ozer@yahoo.com</a></p>
              <a href="https://tr.linkedin.com/in/yalcin-ozer-programmer">
                <img src={linkedin} style={{height: "3rem"}} alt="linkedin"/>
              </a>
              <a href="https://github.com/yalcinozer">
                <img src={github} style={{height: "3rem"}} alt="github"/>
              </a>
            </div>
          </>
        </CenterWrapper>
      </MyPage>
  );
}

export default AboutMeScreen;
