import React from 'react';
import MyPage from "../Components/MyPage";
import Game from "../Components/Game/App";
import {MySocket} from "../Components/Game/Misc/MySocket";
import {yellow} from "../Values/Colors";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

class GameScreen extends React.Component {
  componentWillUnmount() {
    MySocket.disconnect();
  }

  render() {
    return (
        <MyPage style={{backgroundColor: yellow}}>
            <div className="game">
              <Game/>
            </div>
        </MyPage>
    );
  }
}

export default GameScreen;
