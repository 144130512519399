import React from "react";

export const CenterWrapper = (props) => {
  return (
      <div className="container d-flex align-items-center justify-content-center">
        <div className="row">
          <div className="col-12">
            <div className="row justify-content-center">
              {props.children}
            </div>
          </div>
        </div>
      </div>
  )
}
