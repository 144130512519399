import React from 'react';
import MyPage from "../../Components/MyPage";
import {Route, Switch, useRouteMatch} from "react-router-dom";

export const Policy = () => {
    const match = useRouteMatch();
    console.log(match)
    return ( 
        <MyPage>
            <Switch>
                <Route path={match.url+"/multiplayer1a2b"}>
                    <div>
                        <h4 style={{margin:"40px 0"}}>Multiplayer 1A2B</h4>
                        <p>This application does not collect or store personal data.</p>
                        <p>Google Play may collect some personal data. Please see the privacy policy for Google Play Services here: <a href={"https://policies.google.com/privacy"}>https://policies.google.com/privacy</a></p>
                    </div>
                </Route>
                <Route path={match.url+"*"}>
                    <div>
                        <h4 style={{margin:"40px 0"}}>NOT FOUND</h4>
                    </div>
                </Route>
            </Switch>
        </MyPage>
        );
}