import SocketIOClient from 'socket.io-client';

const localURL      = "http://192.168.1.44:8080",
      remoteURL     = "https://numbergame1a2b.herokuapp.com",
      urlWillBeUsed = /*localURL ||*/ remoteURL;

const MySocket = SocketIOClient(urlWillBeUsed, {autoConnect: false, reconnection: false});

export {
    MySocket
};
