import React from 'react';
import MyPage from "../Components/MyPage";
import {yellow} from "../Values/Colors";
import {CenterWrapper} from "../Components/CenterWrapper";
import esmat from "../Assets/images/esmat-temiz.svg"

const Home = (props) => {
  return (
      <MyPage  {...props}>
        <CenterWrapper>
          <div className={"col-12 col-lg-8"}>
            <div className="row">
              <div className="col-12 col-md-6 order-md-2">
                <img
                    className="d-block mx-auto esmat-logo"
                    style={{maxWidth:"100%"}}
                    src={esmat}/>
              </div>
              <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
                <div>
                  <h1>Yalçın ÖZER</h1>
                  <h5 style={{color: yellow}}>Software Developer</h5>
                  <h6 style={{lineHeight: 1.7}}>Hi! I am a self-taught programmer who has mainly worked with JavaScript and
                    related technologies.</h6>
                  <h5 style={{color: yellow}}>Skills</h5>
                  <h6 style={{display: "flex", flexWrap: "wrap"}}>
                    {skillSpans}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </CenterWrapper>
      </MyPage>
  );
}

export default Home;

const skills = ["JS/TS", "React Native", "React", "Node.js", "Express", "MongoDB", "SocketIO", "Git"];

const skillSpans = skills.map((item, index) => {
  return (
      <span key={index} className="skill">{item}</span>
  )
})
