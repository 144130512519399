import React from 'react';
import {Router} from "./Router/Router";
import "./Assets/css/bootstrap.min.css"
import "./Assets/css/App.css";

const App = () => {
  return (
    <Router/>
  );
}

export default App;
